import Vue from 'vue';
import appointmentMapper from '../mappers/appointmentMapper';
import referenceTypes from '../constants/referenceTypes';
import trailerConditions from '../constants/trailerConditions';
import serviceProviders from '../constants/serviceProviders';

async function addAppointment(appointment) {
  const mappedAppointment = appointmentMapper.mapToApiAppointment(appointment);
  const request = [mappedAppointment];

  try {
    const result = await Vue.axios.post('appointment', request);
    if (result.data && result.data.length > 0) {
      return appointmentMapper.mapFromApiAppointment(result.data[0]);
    }
    return appointmentMapper.mapFromApiAppointment(result.data);
  } catch (error) {
    return null;
  }
}

async function updateAppointment(appointment, updatedBy) {
  const mappedAppointment = appointmentMapper.mapToApiAppointment(appointment);
  const request = {
    appointment: mappedAppointment,
    updatedBy,
  };

  try {
    const result = await Vue.axios.put(`appointment/${appointment.id}`, request);
    return appointmentMapper.mapFromApiAppointment(result.data);
  } catch (error) {
    return null;
  }
}

function cancelAppointment(appointment, deletedBy) {
  const request = {
    deletedBy,
  };
  return Vue.axios.delete(`appointment/${appointment.id}`, { data: request });
}

function getAppointments(startDateFilter, endDateFilter) {
  const requestParams = {
    params: {
    },
  };
  requestParams.params.StartDate = startDateFilter.toISOString();
  requestParams.params.EndDate = endDateFilter.toISOString();
  return Vue.axios.get('appointments', requestParams).then((result) => {
    const data = result.data ?? [];
    return appointmentMapper.mapFromApiAppointments(data);
  });
}

async function getNextAvailableAppointmentNumber() {
  try {
    const httpResponse = await Vue.axios.get('appointments/nextAvailableNumber');
    if (httpResponse.status >= 200 && httpResponse.status < 300) {
      return httpResponse.data;
    }
  } catch (error) {
    throw error.response.data;
  }
  return 0;
}

function getReferenceTypeOptions() {
  return [
    referenceTypes.BROKERAGE_REFERENCE_NUMBER,
    referenceTypes.DELIVERY_ORDER_NUMBER,
    referenceTypes.PICK_UP_NUMBER,
    referenceTypes.PO_NUMBER,
    referenceTypes.REFERENCE_NUMBER,
    referenceTypes.SO_NUMBER,
    referenceTypes.TMS_REFERENCE_NUMBER,
  ];
}

function getTrailerConditionOptions() {
  return [
    trailerConditions.BRAKES,
    trailerConditions.CTPAT_FAILED,
    trailerConditions.DAMAGED_CIELING,
    trailerConditions.DAMAGED_DOOR,
    trailerConditions.DAMAGED_FLOOR,
    trailerConditions.DAMAGED_HINGE,
    trailerConditions.DAMAGED_TIRE,
    trailerConditions.DAMAGED_WALL,
    trailerConditions.GOOD,
    trailerConditions.LANDING_GEAR,
    trailerConditions.LIGHTS,
  ];
}

function getServiceProviderOptions() {
  return [
    serviceProviders.AT_T,
    serviceProviders.TMOBILE,
    serviceProviders.VERIZON,
  ];
}

export default {
  addAppointment,
  getAppointments,
  getNextAvailableAppointmentNumber,
  getReferenceTypeOptions,
  getTrailerConditionOptions,
  getServiceProviderOptions,
  updateAppointment,
  cancelAppointment,
};
